import { PageProps } from 'gatsby'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useAsync } from 'react-use'
import styled from 'styled-components/macro'
import { getEvent } from '../../apiClients/events'
import Heading from '../../components/Heading'
import Layout from '../../components/Layout'
import { LayoutContainer } from '../../components/LayoutContainer'
import { MarkdownRenderer } from '../../components/MarkdownRenderer'
import Spinner from '../../components/Spinner'
import StoreItemHeader, {
	propsFromEvent,
} from '../../components/StoreItemHeader'
import { useAppContext } from '../../context'
import Breadcrumb from '../../models/Breadcrumb'
import Entity from '../../models/Entity'
import Event from '../../models/Event'
import { SettingKey } from '../../models/GlobalSetting'
import { buildMetaTitle } from '../../utils/meta'

export default function Events({ params, location }: PageProps) {
	const [event, setEvent] = useState<Entity<Event>>()
	const [loading, setLoading] = useState(true)
	const { getSettingValue } = useAppContext()

	useAsync(async () => {
		setLoading(true)
		const response = await getEvent(params.urlPath)
		if (response) setEvent(response)
		setLoading(false)
	}, [params.urlPath])

	const firstLevel = location.pathname.split('/').filter(p => p)[0]

	const breadcrumbs: Breadcrumb[] = [
		{
			link: `/${firstLevel}`,
			featureName: firstLevel.includes('archive') ? 'events-archive' : 'events',
		},
		{
			link: location.pathname,
			display: event?.attributes.name ?? params.urlPath,
		},
	]

	const header = event ? (
		<StoreItemHeader {...propsFromEvent(event.attributes)} />
	) : null

	const metaTitle = getSettingValue(SettingKey.META_TITLE)

	return (
		<Layout breadcrumbs={breadcrumbs}>
			<Helmet>
				<title>{buildMetaTitle([event?.attributes.name, metaTitle])}</title>
			</Helmet>
			<Container>
				{loading && !event ? (
					<Spinner />
				) : event ? (
					<>
						{event.attributes.markdown && (
							<MarkdownRenderer
								header={header}
								children={event.attributes.markdown}
							/>
						)}
						{event.attributes.writeUp && (
							<MarkdownRenderer
								header={event.attributes.markdown ? null : header}
								children={event.attributes.writeUp}
							/>
						)}
					</>
				) : (
					<Heading>Event not found</Heading>
				)}
			</Container>
		</Layout>
	)
}

const Container = styled(LayoutContainer)`
	display: flex;
	flex-direction: column;
	gap: 20px;
`
