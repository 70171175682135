import moment from 'moment'
import { API_URI } from '.'
import ArrayPayload from '../models/ArrayPayload'
import Entity from '../models/Entity'
import Event from '../models/Event'
import { getHeaders } from './auth'

export const getEvents = async (): Promise<ArrayPayload<Event>> => {
	const response = await fetch(
		`${API_URI}/events` +
			`?populate=logo` +
			`&filters[$and][0][$or][0][bugBounty][$eq]=false` +
			`&filters[$and][0][$or][1][bugBounty][$null]=true` +
			`&filters[$and][1][$or][0][eventEndDate][$null]=true` +
			`&filters[$and][1][$or][1][eventEndDate][$gt]=${moment().utc()}` +
			`&sort[0]=priority:desc`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	return response.json()
}

export const getBugBounties = async (): Promise<ArrayPayload<Event>> => {
	const response = await fetch(
		`${API_URI}/events` +
			`?populate=logo` +
			`&filters[bugBounty][$eq]=true` +
			`&filters[eventEndDate][$gt]=${moment().utc()}` +
			`&sort[0]=priority:desc`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	return response.json()
}

export const getWups = async (): Promise<ArrayPayload<Event>> => {
	const response = await fetch(
		`${API_URI}/events` +
			`?populate=logo` +
			`&filters[showWriteUp]=true` +
			`&filters[$or][0][bugBounty][$eq]=false` +
			`&filters[$or][1][bugBounty][$null]=true` +
			`&sort[0]=priority:desc`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	return response.json()
}

export const getBbups = async (): Promise<ArrayPayload<Event>> => {
	const response = await fetch(
		`${API_URI}/events` +
			`?populate=logo` +
			`&filters[showWriteUp]=true` +
			`&filters[bugBounty][$eq]=true` +
			`&sort[0]=priority:desc`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	return response.json()
}

export const getEvent = async (urlPath: string): Promise<Entity<Event>> => {
	const response = await fetch(
		`${API_URI}/events` +
			`?populate=logo` +
			`&filters[urlPath][$eq]=${urlPath}` +
			`&filters[bugBounty][$eq]=false` +
			`&sort[0]=priority:desc`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	const json = await response.json()

	return json.data[0]
}

export const getBugBounty = async (urlPath: string): Promise<Entity<Event>> => {
	const response = await fetch(
		`${API_URI}/events` +
			`?populate=logo` +
			`&filters[urlPath][$eq]=${urlPath}` +
			`&filters[bugBounty][$eq]=true` +
			`&sort[0]=priority:desc`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	const json = await response.json()

	return json.data[0]
}
