import moment from 'moment'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import BlogEntry from '../models/BlogEntry'
import Event from '../models/Event'
import Package from '../models/Package'
import Service from '../models/Service'
import Video from '../models/Video'
import FlatButton from './FlatButton'
import PromoteRibbon from './PromoteRibbon'

interface Props {
	title: string
	description?: string
	price?: string
	logo?: string
	fitLogo?: boolean
	promote?: boolean
	hasTokenLeft?: boolean
	onClick?: () => void
}

export default function StoreItemHeader({
	title,
	description,
	price,
	logo,
	fitLogo,
	promote,
	hasTokenLeft,
	onClick,
}: Props) {
	return (
		<Container>
			<Logo src={logo} fit={fitLogo}>
				{promote && <PromoteRibbon />}
			</Logo>
			<Content>
				<Title>{title}</Title>
				<Description>{description}</Description>
				{onClick && (
					<>
						<Price>{price || 'Free'}</Price>
						<BuyButton onClick={onClick} disabled={!hasTokenLeft}>
							{!hasTokenLeft ? 'No Code left' : price ? 'Buy' : 'Get'}
						</BuyButton>
					</>
				)}
			</Content>
		</Container>
	)
}

export const propsFromBlogEntry = (entry: BlogEntry): Props => ({
	title: entry.title,
	logo: entry.logo?.data?.attributes.url,
	fitLogo: entry.fitLogo,
})

export const propsFromVideo = (video: Video): Props => ({
	title: video.title,
	logo: video.logo?.data?.attributes.url,
	fitLogo: video.fitLogo,
})

export const propsFromService = (service: Service): Props => ({
	title: service.title,
	logo: service.logo?.data?.attributes.url,
	fitLogo: service.fitLogo,
})

export const propsFromEvent = (event: Event): Props => ({
	title: event.name,
	description:
		event.showEventEndDate && event.eventEndDate
			? `Until ${moment(event.eventEndDate).format('DD.MM.YYYY HH:mm')}`
			: undefined,
	logo: event.logo?.data?.attributes.url,
	fitLogo: event.fitLogo,
})

export const propsFromPackage = (
	pkg: Package,
	hasTokenLeft: boolean
): Props => ({
	title: pkg.name,
	description: pkg.description,
	price: pkg.price ? `${pkg.currency} ${pkg.price}.-` : 'FREE',
	logo: pkg.logo?.data?.attributes.url,
	fitLogo: pkg.fitLogo,
	promote: pkg.promote,
	hasTokenLeft,
	onClick: pkg.storeLink
		? () => {
				window.open(pkg.storeLink, '_blank', 'noopener noreferrer')
		  }
		: undefined,
})

const Container = styled.div`
	align-self: start;
	display: flex;
`

interface LogoProps {
	src?: string
	fit?: boolean
}

const Logo = styled.div`
	position: relative;
	height: 155px;
	width: 155px;
	@media (max-width: 500px) {
		height: 90px;
		width: 90px;
	}
	${({ src, fit }: LogoProps) =>
		src
			? css`
					background-image: url('${src}');
					background-position: center;
					background-size: ${fit ? 'cover' : 'contain'};
					background-repeat: no-repeat;
			  `
			: null}
`

const Price = styled.div`
	font-size: 1.2em;
	font-weight: 500;
`

const Content = styled.div`
	flex: 1;
	min-height: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: 15px;
`

const Title = styled.div`
	font-family: Heading;
	font-weight: 600;
	font-size: 1.6em;
	@media (max-width: 500px) {
		font-size: 1.4em;
	}
`

const Description = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1;
	font-weight: 300;
`

const BuyButton = styled(FlatButton)`
	align-self: flex-start;
	margin-top: 1em;
`
